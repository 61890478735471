import { ATTENDANCE_REGISTER_USER,RESET_ATTENDANCE_REGISTER } from "../../constants/ActionTypes";

const INIT_STATE = {
    attendance_register: null,

};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case ATTENDANCE_REGISTER_USER: {
            return {
                ...state,
                attendance_register: action.payload,
            }
        }

        case RESET_ATTENDANCE_REGISTER: {
            return {
                ...state,
                attendance_register: null,
            }
        }
        
        default:
            return state;
    }
}

