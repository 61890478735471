import {combineReducers} from "redux";
import Settings from "./Settings";
import Auth from "./Auth";
import Common from "./Common";
import Company from "./Company";
import Employee from "./Employee";
import Candidate from "./Candidate";
import Payroll from "./Payroll";
import AttendanceRegister from './Attendance'
import {connectRouter} from 'connected-react-router';

export default (history) => combineReducers({
    router: connectRouter(history),
    settings: Settings,
    auth: Auth,
    common: Common,
    company: Company,
    employee: Employee,
    candidate: Candidate,
    payroll:Payroll,
    attendanceregister:AttendanceRegister
});
