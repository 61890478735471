import { ATTENDANCE_REGISTER_USER,RESET_ATTENDANCE_REGISTER } from "../../constants/ActionTypes";

export const setAttendanceRecord = (data)=>{
return {
        type: ATTENDANCE_REGISTER_USER,
        payload: data
    };
}

export const resetAttendanceRecord = () => {
    return {
        type: RESET_ATTENDANCE_REGISTER,
    };
}
